<template>
   <y-layout-body-menu aside-width="8rem" :menu="[
       {title:'购买页配置',path:'/card/system/card-conf-edit'},
       {title:'购买页轮播',path:'/card/system/card-conf-banner'},
       {title:'购买页权益',path:'/card/system/card-conf-rights'},
       {title:'购买页公告',path:'/card/system/card-conf-notice'},
       {title:'个人中心轮播',path:'/card/system/card-conf-page-mine-banner'},
       {title:'免单卡规则',path:'/card/system/card-rule-card'},
       {title:'商家规则',path:'/card/system/card-rule-merchant'},
       {title:'卡券海报',path:'/card/system/card-rule-coupon-poster'},
       {title:'免单海报',path:'/card/system/card-rule-poster'},
       {title:'平台管理员',path:'/card/system/card-rule-manage'},
   ]">
     <router-view/>
   </y-layout-body-menu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>